.card-blog{
  position: relative;
  :global{
    .ant-card-cover{
      height: 0;
      padding-top: 66%;
      position: relative;
      img{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }
    .ant-card-meta-description{
      height: 70px;
      overflow: hidden;
    }
  }
  .card-blog-tolbar{
    position: absolute;
    top: 15px;
    inset-inline-end: 13px;
    z-index: 1  ;
    .delete,.edit{
      height: 25px;
      background-color: #FFF;
      width: 25px;
      cursor: pointer;
      font-size: 16px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0px 2px;
      border-radius: 2px;
    }
    .delete{
      color: #FF0000;
    }
    .edit{
      color: #48DC1B;
    }
  }
}

.blog-single{
  label{
    display: block;
    background-color: rgba(143, 143, 143 , .28);
    border-radius: 10px;
    min-height: 300px;
    padding: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  input{
    opacity: 0;
    width: 0;
    height: 0;
  }
  .blog-single-image{
    img{
      max-height: 300px;
    }
  }
}
}


.card-blog-single-image{
  img{
    width: 100%;
  }
}