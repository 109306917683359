.main-header{
    padding: 10px 0;
    box-shadow: 0 3px 6px rgba(0, 0, 0 , .16);
    background-color: #FFF;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 4;
    .logo{
        max-height: 70px;
    }
    .menu-nav{
        display: flex;
        align-items: center;
        justify-content: center;
        li{
            a{
                display: inline-block;
                padding: 2px 12px;
                font-size: 20px;
                color: #000;
                margin: 0px 10px;
                border-radius: 30px;
                transition: color .2s ease-in-out  , background-color .2s ease-in-out;
                @media(max-width:991px){
                    padding: 10px;
                    display: block;
                }
                &:hover,&.active{
                    color: #fff;
                    background-color: #523F94;
                }
            }
            :global{
                .active{
                    color: #fff;
                    background-color: #523F94;
                }
            }
        }
    }
    .btn-lang{
        border: 2px solid #D2D3D5;
        border-radius: 5px;
        background-color: #FFF;
        padding: 8px 25px;
        display: flex;
        cursor: pointer;
        align-items: center;
        img{
            max-height: 15px;
        }
    }
}

.barsBtn{
    display: inline-block;
    position: relative;
    height: 2px;
    width: 34px;
    border-radius: 1px;
    background: #293335;
    &::before, &::after{
        display: inline-block;
        content: "";
        height: 2px;
        border-radius: 1px;
        background: #293335;
        transition: all 200ms;
        position: absolute;
    }
    &::before{
        top: -11px;
        left: 3px;
        width: 28px;
    }
    &::after{
        top: 11px;
        left: 6px;
        width: 22px;
    }

}

@media(min-width:991px){
    .menu-mobile{
        display: none;
    }
}

@media(max-width:991px){
    .menu{
        display: none;
    }
}

.flex-auto{
    flex: 0 0 auto;
    width: auto; 
}


.close {
    position: absolute;
    inset-inline-end: 12px;
    top: 12px;
    width: 25px;
    height: 25px;
    opacity: 0.3;
    cursor: pointer;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 28px;
    width: 2px;
    background-color: #333;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }

:global{
    .ant-drawer{
        ul{
            li{
                a{
                    display: block;
                    padding: 14px 0px;
                    border-bottom: 1px solid #EEE;
                }
            }
        }
    }
}