.section{
    padding: 70px 0;
}

.section-home{
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   position: relative;
   z-index: 1;
   display: flex;
   align-items: center;
   
   overflow: hidden;
   &::before{
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #523F94;
    opacity: .24;
    z-index: -1;
    top: 0;
    left: 0;
   }
   .home-title{
    font-size: 32px;
   }
   @media(min-width:992px){
    height: 700px;
    border-radius:  0 0 130px 130px;
   }
   @media(max-width:992px){
    padding: 100px 0;
    border-radius:  0 0 40px 40px;
   }
}


.widget_item-ouradvisers{
    position: relative;
    &::before{
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background-color: #707070;
        opacity: .4;
        top: 0;
        left: 0;
        z-index: 1;
    }
    .widget_item-image{
        height: 0;
        padding-top: 140%;
        position: relative;
        img{
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            object-fit: cover;
            height: 100%;
        }
    }
    .widget_item-content{
        position: absolute;
        bottom: 100px;
        right: 0;
        padding: 10px 20px;
        z-index: 2;
    }
}

.list-conventsions{
    :global{
        .ant-row{
            &:nth-child(odd){
                flex-direction: row-reverse;
            }
        }
    }
}

.about-title{
    font-size: 32px;
}


.widget_item-goals{
    box-shadow: 0 3px 6px rgba(0, 0, 0 , .16);
    background-color: #FAFAFA;
    border-radius: 20px;
    padding: 40px 20px;
    height: 100%;
    .widget_item-icon{
        height: 70px;
        margin-bottom: 30px;
        img{
            max-height: 70px;
        }
    }
    .widget_item-title{
        color: rgba(0, 0, 0 , .5);
    }
}


.widget_item-values{
    .widget_item-icon{
        height: 80px;
        img{
            max-height: 80px;
        }
    }
}

.btn-download-app{
  background-color: #FFF;
  display: inline-block;
  padding: 11px 25px;
  border-radius: 4px;
  font-size: 16px;
  transition: all .2s ease-in-out;
  &:hover{
    background-color: #523F94;
    color: #FFF;
  }
}


.widget_item-blog{
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 10px #EEE;
  display: block;
  .widget_item-image{
    height: 0;
    padding-top: 66%;
    position: relative;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      top: 0;
      left: 0;
      position: absolute;
    }
  }
  .widget_item-content{
    padding: 15px;
    .widget_item-title{
      height: 65px;
      overflow: hidden;
    }
    .widget_item-desc{
      height: 52px;
      overflow: hidden;
    }
  }
}