.main-footer{
    background-color: #523F94;
    padding: 70px 0;
    .logo{
        @media(max-width:991px){
            margin: 30px auto 0;
        }
    }
    .footer-link{
      display: inline-block;
      padding: 5px;
      font-size: 18px;
      svg{
        margin-inline-end: 10px;
      }
    }
}

.social-media{
  img{
    height: 30px;
    margin: 0 5px;
  }
}